import React, { useState } from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { Helmet } from "react-helmet"
import styled from "@emotion/styled"
import FsLightbox from "fslightbox-react"
import BackgroundImage from "gatsby-background-image"
import Layout from "../components/Layout"
import { InteriorContent } from "../components/InteriorContent"
import Img from "gatsby-image"
import underline from "../images/underline.png"
import WhatOurClientsAreSaying from "../components/WhatOurClientsAreSaying"
import Maps from "../components/Maps"
import {
  ContentSidebarContainerNoFeaturedImage,
  LineBottom,
  HeaderSection,
} from "../components/PageElements"
import { SpecialFAQForm } from "../components/SpecialFAQForm"
import SpecialFAQSidebar from "../components/SpecialFAQSidebar"
import favicon from "../images/favicon.png"

const InteriorContentFaq = styled(InteriorContent)`
  strong {
    font-size: 22px;
    font-weight: 900;
  }
  p,
  ul {
    font-size: 18px;
  }
  .caution {
    border: 5px solid #000;
    text-align: center;
    padding: 10px 60px;
    margin: 20px 0;
    font-size: 26px;
  }
`
const NewFaqTemplate = ({ data, pageContext }) => {
  const faqpage = data.wpgraphql.faq
  const [toggler, setToggler] = useState(false)
  const breakpoints = useBreakpoint()

  const {
    title,
    metaDesc,
    opengraphTitle,
    twitterDescription,
    twitterTitle,
  } = pageContext.seo
  return (
    <Layout>
      <Helmet>
        <link rel="icon" href={favicon} />
        <title>{opengraphTitle ? opengraphTitle : title}</title>
        <meta name="description" content={metaDesc ? metaDesc : ""} />
        {pageContext.seo.opengraphImage !== null && (
          <meta
            name="image"
            content={pageContext.seo.opengraphImage.mediaItemUrl}
          />
        )}
        <meta
          property="og:url"
          content={`https://www.mmklawfirm.com/${pageContext.slug}`}
        />
        <meta
          property="og:title"
          content={opengraphTitle ? opengraphTitle : title}
        />
        <meta property="og:description" content={metaDesc ? metaDesc : ""} />
        {pageContext.seo.opengraphImage !== null && (
          <meta
            property="og:image"
            content={pageContext.seo.opengraphImage.mediaItemUrl}
          />
        )}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content={`https://www.mmklawfirm.com/${pageContext.slug}`}
        />
        <meta
          name="twitter:title"
          content={
            twitterTitle
              ? twitterTitle
              : opengraphTitle
              ? opengraphTitle
              : title
          }
        />
        <meta
          name="twitter:description"
          content={
            twitterDescription ? twitterDescription : metaDesc ? metaDesc : ""
          }
        />
        {pageContext.seo.opengraphImage != null ||
          (pageContext.seo.twitterImage != null && (
            <meta
              name="twitter:image"
              content={
                pageContext.seo.twitterImage
                  ? pageContext.seo.twitterImage.mediaItemUrl
                  : pageContext.seo.opengraphImage
                  ? pageContext.seo.opengraphImage.mediaItemUrl
                  : ""
              }
            />
          ))}
      </Helmet>
      <ContentSidebarContainerNoFeaturedImage>
        <InteriorContentFaq>
          <HeaderSection>
            <h3>{faqpage.title}</h3>
            <h1>PENNSYLVANIA WORKERS' COMPENSATION ATTORNEYS</h1>
            <LineBottom>
              {breakpoints.sm ? (
                <>
                  <Img fluid={data.underline.childImageSharp.fluid} />
                </>
              ) : (
                <img className="underline" src={underline} />
              )}
            </LineBottom>
          </HeaderSection>
          <div className="sc-bwzfXH row uTxCW">
            <div className="col-12 col-lg-9">
              <div className="sc-jwKygS video_bg_red fBWxJW">
                <a onClick={() => setToggler(!toggler)}>
                  <Img
                    fluid={data.video2.childImageSharp.fluid}
                    loading="lazy"
                  />
                  <div className="vidio_icon">
                    <Img
                      fixed={data.videoBtn.childImageSharp.fixed}
                      loading="lazy"
                    />
                  </div>

                  <BackgroundImage
                    fluid={data.videoImgBg.childImageSharp.fluid}
                    className="bg_abs"
                    loading="lazy"
                  >
                    <span>GET JUSTICE FOR YOUR PAIN AND SUFFERING</span>
                  </BackgroundImage>
                </a>
              </div>
            </div>
          </div>
          <FsLightbox
            toggler={toggler}
            customSources={[
              <iframe
                src={`https://player.vimeo.com/video/${faqpage.FAQ_Vimeo_Video.vimeoVideoId}?autoplay=1`}
                width="1920px"
                height="1080px"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              />,
            ]}
          />
          <div dangerouslySetInnerHTML={{ __html: faqpage.content }} />
          <SpecialFAQForm />
        </InteriorContentFaq>
        <SpecialFAQSidebar />
      </ContentSidebarContainerNoFeaturedImage>
      <WhatOurClientsAreSaying />
      <Maps />
    </Layout>
  )
}

export default NewFaqTemplate

export const query = graphql`
  query($id: ID!) {
    wpgraphql {
      faq(id: $id) {
        title
        content
        FAQ_Vimeo_Video {
          vimeoVideoId
        }
      }
    }
    underline: file(relativePath: { eq: "underline.png" }) {
      childImageSharp {
        fluid(maxWidth: 501, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
        fixed(width: 660, height: 18, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    video2: file(relativePath: { eq: "new-faq-video-thumb.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 755, maxHeight: 500) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    videoBtn: file(relativePath: { eq: "video_btn.png" }) {
      childImageSharp {
        fixed(width: 135, height: 135) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    videoImgBg: file(relativePath: { eq: "video_img_bg.png" }) {
      childImageSharp {
        fluid(maxWidth: 755, maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

import React, { useState } from "react"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import { useStaticQuery, navigate } from "gatsby"
import styled from "@emotion/styled"
import BackgroundImage from 'gatsby-background-image'

const SpecialFAQFormContainer = styled(BackgroundImage)`
padding: 30px;
h4, p {
    color: #fff;
}
h4{
    font-size: 33px;
}
margin: 40px 0 20px;
@media(min-width: 992px){
  position: relative;
}
@media(min-width: 1288px){
    margin: 80px 0 20px;  
}
`
const FieldWrapper = styled.div`
margin-bottom: 41px;

label{
  color: #fff;
    font-size: 14px;
    display: inline-block;
    margin-bottom: .5rem;
    text-transform: uppercase;
}
.error{
  color: #fff;
}
`
const ButtonWrapper = styled.div`
margin-top: 40px;
text-align: center;
text-transform: uppercase;

button{
  font-size: 12px;
  background: 0 0;
  border-radius: 0;
  color: #fff;
  font-family: averta-bold;
  text-transform: uppercase;
  padding: 12px 46px;
  position: relative;
  display: inline-block;
}
button, button:after{
  border: 1px solid #fff;
}
button:hover{
  cursor: pointer;
}
button:after{
  content: "";
  position: absolute;
  width: calc(100% - 18px);
  height: 131%;
  left: 10px;
  right: 0;
  top: -7px;
}
`
const SubmitError = styled.div`
  position: absolute;
  text-align: center;
  font-size: 0.8rem;
  bottom: 1rem;
  left: 0;
  width: 100%;
  color: crimson;

`
const SpecialFAQFormHeaderContainer = styled.div`
margin-bottom: 40px;
`
const SpecialFAQFormHeader = styled.h4`
text-align: center;
    color: #fff;
    font-size: 33px;
    font-family: PlayfairDisplay-Regular;
    margin-bottom: 22px;
`
const SpecialFAQFormP = styled.p`
text-align: center;
    color: #fff;
    letter-spacing: 2px;
    text-transform: uppercase;
`
const FieldStyled = styled(Field)`
background: 0 0;
    border: 0;
    border-bottom: 2px solid #ed878d;
    border-radius: 0;
    padding-left: 0;
    color: #fff;
    outline: 0!important;
    box-shadow: none!important;
    min-height: 30px;
display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
`


function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const Schema = Yup.object().shape({
  first_name: Yup.string()
    .required("Please enter your first name."),
  last_name: Yup.string()
    .required("Please enter your last name."),
    phone_number: Yup.string()
    .required("Please enter your phone number.")
    .matches(phoneRegExp, 'Please enter your phone number in the following format: 555-555-5555'),
  email_address: Yup.string()
    .email("Please enter your email in the following format: example@sample.com")
    .required("Please enter your email address."),
})
export const SpecialFAQForm = ({}) => {
    const [error, setError] = useState(false)
  function handleSubmit(values, setSubmitting, resetForm) {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "new-faqs-form",
        ...values,
      }),
    })
      .then(() => {
        navigate("/thank-you/", {
          state: { submitted: true },
        })
      })
      .catch(() => {
        resetForm()
        setSubmitting(false)
        setError(true)
      })
  }

  const data2 = useStaticQuery(graphql`
  {
    file(relativePath: { eq: "interior-form-background.png" }) {
        childImageSharp {
          fluid(maxWidth: 660) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
`)

    return(
        <SpecialFAQFormContainer fluid={data2.file.childImageSharp.fluid} critical>
        <SpecialFAQFormHeaderContainer>
<SpecialFAQFormHeader>
Request A Free Consultation
</SpecialFAQFormHeader>
<SpecialFAQFormP>
Fill out the form below
</SpecialFAQFormP>
        </SpecialFAQFormHeaderContainer>
        <Formik
      initialValues={{
        first_name: "",
        last_name: "",
        phone_number: "",
        email_address: "",
        date: "",
        how_injured: "",
        body_parts: "",
        claim_accepted: "blank",
      }}
      validationSchema={Schema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        handleSubmit(values, setSubmitting, resetForm)
      }}
    >
        {({ isSubmitting }) => (
             <Form
             method="post"
             name="new-faqs-form"
             data-netlify="true"
             data-netlify-honeypot="bot-field"
           >
             <input type="hidden" name="bot-field" />
             <input type="hidden" name="form-name" value="new-faqs-form" />
   
             <FieldWrapper>
               <label htmlFor="first_name">First Name *</label>
             <FieldStyled type="text" name="first_name" required/>
                   <div className="error">
                 <ErrorMessage name="first_name" />
               </div>
                   </FieldWrapper>

                   <FieldWrapper>
               <label htmlFor="last_name">Last Name *</label>
             <FieldStyled type="text" name="last_name" required />
                   <div className="error">
                 <ErrorMessage name="last_name" />
               </div>
                   </FieldWrapper>

                   <FieldWrapper>
            <label htmlFor="phone_number">Phone *</label>
            <FieldStyled type="tel" name="phone_number" className="input" required />
            <div className="error">
              <ErrorMessage name="phone_number" />
            </div>
          </FieldWrapper>

          <FieldWrapper>
            <label htmlFor="email">Email *</label>
            <FieldStyled type="email" name="email_address" className="input" required />
            <div className="error">
              <ErrorMessage name="email_address" />
            </div>
          </FieldWrapper>

          <FieldWrapper>
            <label htmlFor="date">Date of Injury?</label>
            <FieldStyled name="date" type="date" className="input" />
          </FieldWrapper>

          <FieldWrapper>
            <label htmlFor="how_injured">How Were You Injured?</label>
            <FieldStyled name="how_injured" className="input" />
          </FieldWrapper>

          <FieldWrapper>
            <label htmlFor="body_parts">What Body Parts Did You Injure?</label>
            <FieldStyled name="body_parts" className="input" />
          </FieldWrapper>

          <FieldWrapper>
            <label htmlFor="claim_accepted">Has Your Claim Been Accepted?</label>
            <FieldStyled as="select" name="claim_accepted" className="input">
            <option value="blank"></option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </FieldStyled>
          </FieldWrapper>

                   <ButtonWrapper>
            <button type="submit" disabled={isSubmitting}>
              Submit Your Case
            </button>
          </ButtonWrapper>
          {error && (
            <SubmitError>Something went wrong. Please try again!</SubmitError>
          )}
                   </Form>
        )}
    </Formik>
    </SpecialFAQFormContainer>
    )

}